import { DataExplorerNodeType } from "@fscrypto/domain/data-schema";
import clsx from "clsx";
import { Columns2Icon, Table2Icon, TableProperties, TablePropertiesIcon } from "lucide-react";

interface DatabaseExplorerIconProps {
  type: DataExplorerNodeType;
  name: string;
}

const defaultIconStyles = "text-muted-foreground size-4 m-1 group-hover:text-secondary-foreground flex-shrink-0";

const DatabaseExplorerIcon = ({ type, name }: DatabaseExplorerIconProps) => {
  if (type === "database") return <DatabaseIcon name={name} />;
  if (type === "schema") return <SchemaIcon name={name} />;
  if (type === "table")
    return <TablePropertiesIcon name={name} className="text-muted-foreground size-4 flex-shrink-0" />;
  if (type === "column") return <Columns2Icon name={name} className="text-muted-foreground size-4" />;
  return <Table2Icon className={defaultIconStyles} />;
};

export default DatabaseExplorerIcon;

export const DatabaseIcon = ({ name, size = "md" }: { name: string; size?: "sm" | "md" }) => {
  const fileName = imageMap[name];
  const className = clsx("flex-shrink-0", {
    "h-4 w-4": size === "sm",
    "h-5 w-5 m-1": size === "md",
  });

  if (fileName) {
    return (
      <img
        src={`https://res.cloudinary.com/dsr37ut2z/image/upload/assets/project-icons/${fileName}`}
        alt={name}
        className={className}
      />
    );
  } else {
    return (
      <TableProperties
        name={name}
        className={clsx("text-muted-foreground", {
          "size-4": size === "sm",
          "size-5": size === "md",
        })}
      />
    );
  }
};

const SchemaIcon = ({ name }: { name: string }) => {
  const fileName = imageMap[name];

  if (fileName) {
    return (
      <img
        src={`https://res.cloudinary.com/dsr37ut2z/image/upload/assets/project-icons/${fileName}`}
        alt={name}
        className="m-1 h-5 w-5 flex-shrink-0"
      />
    );
  } else {
    return <Table2Icon className={defaultIconStyles} />;
  }
};

const imageMap: Record<string, string> = {
  aave: "aave.svg",
  aptos: "aptos.png",
  arbitrum: "arbitrum.svg",
  aurora: "aurora.svg",
  avalanche: "avalanche.svg",
  axelar: "axelar.png",
  base: "base.png",
  beacon_chain: "beacon.png",
  berachain: "berachain.svg",
  beta: "eth_beta.svg",
  bitcoin: "bitcoin.svg",
  blast: "blast.svg",
  blitz: "blitz-icon.png",
  bsc: "bsc.svg",
  celo: "celo.svg",
  chainlink: "chainlink.svg",
  classic: "terra-classic.png",
  compound: "compound.svg",
  cosmos: "cosmos.svg",
  crosschain: "flipside.svg",
  deepnftvalue: "deep-nft-value.png",
  defillama: "defillama.svg",
  dexalot: "dexalot.svg",
  eclipse: "eclipse.svg",
  ens: "ens.svg",
  ethereum: "ethereum.svg",
  evmos: "evmos.svg",
  external: "flipside.svg",
  farcaster: "farcaster.png",
  flashbots: "flashbots.svg",
  flow: "flow.svg",
  gnosis: "gnosis.svg",
  goerli: "base.png",
  harmony: "harmony.svg",
  horizon: "horizon.svg",
  kaia: "klaytn.svg",
  klaytn: "klaytn.svg",
  lava: "lava.svg",
  layerzero: "layerzero.png",
  m1: "m1.svg",
  maker: "maker.svg",
  mars: "mars.svg",
  near: "near.svg",
  olas: "olas.svg",
  optimism: "optimism.svg",
  osmosis: "osmosis.png",
  polygon: "polygon.svg",
  sei: "sei.svg",
  snapshot: "snapshot.svg",
  social: "near-social.svg",
  solana: "solana.png",
  sushi: "sushi.svg",
  synthetix: "synthetix.svg",
  terra: "terra.svg",
  thorchain: "thorchain.png",
  tokenflow_eth: "tokenflow.ico",
  tokenflow_starknet: "starknet.svg",
  tokenlists: "tokenlists.png",
  uniswapv3: "uniswap.svg",
  velodrome: "velodrome.svg",
  vertex: "vertex.png",
};
